import { useTableOptions } from '@/composables'
import { fetchSettings } from '@api/site/site'
import { reactive, ref, watch } from '@vue/composition-api'

const cached = []

export default function useList(useOptions = {}) {
  const loading = ref(false)

  const tableList = ref([])

  // Table Handlers
  const tableColumns = [
    { text: 'ID', value: 'id' },
    { text: 'Category', value: 'category' },
    { text: 'Key', value: 'key' },
    { text: 'Value', value: 'value' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const tableTotal = ref(0)
  const tableSelectedData = ref([])

  const options = ref(
    cached[0] || {
      sortBy: ['id'],
      sortDesc: [true],
    },
  )

  // Filter
  const filters = reactive({
    status: null,
  })

  const search = ref(null)

  const parseFilters = () =>
    Object.keys(filters)
      .filter(key => filters[key])
      .reduce((a, key) => ({ ...a, [key]: filters[key] }), {})

  const loadSettings = async () => {
    loading.value = true

    try {
      const params = {
        ...useTableOptions(options.value),
        ...parseFilters(),
        ...(search.value && { search: search.value }),
      }

      const {
        status,
        data,

        // data: {
        //   data: { records, pagination },
        // },
      } = await fetchSettings(useOptions.site_id, params)

      if (status === 200) {
        tableList.value = data.data
        tableTotal.value = data.data.length
      }
    } catch (error) {
      console.log(error)
    } finally {
      loading.value = false
    }
  }

  const cachable = [options]

  const cache = () => {
    cachable.forEach((variable, index) => {
      cached[index] = variable.value
    })
  }

  watch(cachable, () => {
    const newPage = options.value.page

    const hasDiff = cachable.find((variable, index) => index > 0 && cached[index] !== variable.value)

    if (newPage >= 2 && hasDiff) {
      options.value.page = 1
    }

    loading.value = true
    tableSelectedData.value = []

    loadSettings()
    cache()
  })

  return {
    options,
    filters,
    search,

    tableList,
    tableColumns,
    tableTotal,
    tableSelectedData,

    loading,
    loadSettings,
  }
}
