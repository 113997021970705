<template>
  <div>
    <v-card class="filter-section">
      <v-row class="">
        <v-col cols="6">
          <v-card-title class="font-weight-bold ">
            {{ $t('common.list.filters') }}
          </v-card-title>
        </v-col>
        <v-col
          cols="6"
          class="d-flex justify-end"
        >
          <v-btn
            icon
            @click="showFilter = !showFilter"
          >
            <v-icon>{{ showFilter ? icons.mdiMenuUp : icons.mdiMenuDown }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-expand-transition>
        <v-row
          v-show="showFilter"
          class="px-2 ma-0"
        >
          <v-col
            cols="4"
          >
            <v-select
              v-model="filters.status"
              placeholder="Filter Status"
              :items="statusOptions"
              item-text="title"
              item-value="value"
              single-line
              dense
              outlined
              clearable
              hide-details
            ></v-select>
          </v-col>
        </v-row>
      </v-expand-transition>
    </v-card>

    <v-row class="my-0 d-flex justify-end">
      <v-col
        cols="12"
        sm="2"
        class="mr-auto"
      >
        <v-btn
          color="primary"
          block
          class=""
          :loading="loading"
          :disabled="loading"
          @click="reload"
        >
          <v-icon class="prefix-icon">
            {{ icons.mdiRefresh }}
          </v-icon>
          <span>
            {{ $t('common.list.refresh') }}
          </span>
        </v-btn>
      </v-col>
      <!-- <v-col
        cols="12"
        sm="3"
      >
        <v-btn
          depressed
          block
          color="success"
          @click="setAside(null)"
        >
          <v-icon class="prefix-icon">
            {{ icons.mdiPlus }}
          </v-icon>
          Create an Site Setting
        </v-btn>
      </v-col> -->
    </v-row>

    <v-card>
      <!-- table -->
      <!-- :single-select="singleSelect" show-select -->
      <v-data-table
        v-model="tableSelectedData"
        :headers="tableColumns"
        :items="tableList"
        :options.sync="options"
        :server-items-length="tableTotal"
        :loading="loading"
        class="table-section text-no-wrap"
        :header-props="headerprops"
        :hide-default-footer="true"
      >
        <template #[`item.id`]="{ item }">
          #{{ item.id }}
        </template>

        <template #[`item.name`]="{ item }">
          <router-link
            class="text-decoration-none"
            :to="{ name: 'site-setting-view', params: { id: item.id }}"
          >
            {{ t(item.name) }}
          </router-link>
        </template>

        <template #[`item.category`]="{ item }">
          {{ item.category }}
        </template>

        <template #[`item.key`]="{ item }">
          {{ item.key }}
        </template>

        <template #[`item.value`]="{ item }">
          {{ item.value }}
          <b v-if="item.children.length">
            - default
          </b>
          <div
            v-for="child in item.children"
            :key="child.key"
            class="ml-2"
          >
            {{ child.value }} - <b>{{ child.key }}</b>
            <v-icon @click="setAside(child)">
              {{ icons.mdiPencilOutline }}
            </v-icon>
          </div>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <div class="d-flex align-center justify-center">
            <!-- dropdown -->
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="18">
                    {{ icons.mdiDotsVertical }}
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  link
                  @click="setAside(item, 'setting')"
                >
                  <v-list-item-title>
                    <v-icon
                      size="18"
                      class="me-2"
                    >
                      {{ icons.mdiPencilOutline }}
                    </v-icon>
                    <span>{{ $t('common.list.edit') }}</span>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item
                  v-if="['site.home_href', 'site.referrer_url'].includes(item.key)"
                  link
                  @click="setAside(item, 'store_child')"
                >
                  <v-list-item-title>
                    <v-icon
                      size="18"
                      class="me-2"
                    >
                      {{ icons.mdiPlus }}
                    </v-icon>
                    <span>Add Child Distributor Value</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <site-setting-aside
      v-if="aside === 'setting' && asideOpen"
      v-model="asideOpen"
      :resource="resource"
      :site-id="site_id"
      @changed="() => {
        reload()
        aside = ''
      }"
    />

    <store-site-setting-child-aside
      v-if="aside === 'store_child' && asideOpen"
      v-model="asideOpen"
      :resource="resource"
      :site-id="site_id"
      @changed="() => {
        reload()
        aside = ''
      }"
    />
  </div>
</template>

<script>
import {
mdiDatabaseExportOutline,
mdiDeleteOutline,
mdiDotsVertical,
mdiMenuDown,
mdiMenuUp,
mdiPencilOutline,
mdiPlus,
mdiRefresh,
} from '@mdi/js'

import { t } from '@/plugins/i18n'
import router from '@/router'
import { formatDate } from '@core/utils/filter'
import { ref } from '@vue/composition-api'
import SiteSettingAside from '../site-setting-resource/SiteSettingAside.vue'
import StoreSiteSettingChildAside from '../site-setting-resource/StoreSiteSettingChildAside.vue'
import useSiteSettingList from './useSiteSettingList'

export default {
  components: {
    SiteSettingAside,
    StoreSiteSettingChildAside,
  },

  setup() {
    const site_id = router.currentRoute.params.id

    const {
      tableList,
      tableColumns,
      tableTotal,
      tableSelectedData,

      statusOptions,
      options,
      filters,
      search,

      loadSettings,
      deleteSiteSetting,

      loading,
    } = useSiteSettingList({
      site_id,
    })

    const reload = () => loadSettings(site_id)

    const actions = ['Delete', 'Edit', 'Send']

    const selectedAction = ref('')

    const aside = ref('')
    const asideOpen = ref(false)
    const resource = ref(null)

    const setAside = (item, type = 'setting') => {
      aside.value = type
      asideOpen.value = true
      console.log('setting aside value', type)
      resource.value = item
    }

    const headerprops = ref({
      'sort-icon': mdiMenuDown,
    })
    const showFilter = ref(true)

    return {
      site_id,

      tableColumns,
      tableList,
      tableSelectedData,
      tableTotal,

      setAside,
      aside,
      asideOpen,
      resource,

      statusOptions,
      options,
      filters,
      search,

      loading,
      actions,
      selectedAction,
      reload,
      deleteSiteSetting,

      t,
      formatDate,

      icons: {
        mdiDotsVertical,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiMenuDown,
        mdiMenuUp,
        mdiRefresh,
        mdiPlus,
        mdiDatabaseExportOutline,
      },

      headerprops,
      showFilter,
    }
  },
}
</script>
